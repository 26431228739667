<template>
  <v-dialog v-model="dialog" persistent max-width="800px">
    <template #activator="{ on }">
      <slot :on="on"></slot>
    </template>
    <v-card class="p-5 p-md-8">
      <ModalHeading
        :modalTitle="formMode === 'add' ? 'Create Contract' : 'Edit Contract'"
      />
      <form class="form pt-3" @submit.prevent="submitForm">
        <div class="row">
          <div class="col-12 mb-7">
            <label class="form__label"
              >Client <span class="text-danger">*</span></label
            >
            <v-autocomplete
              v-model="selectedClient"
              :items="clientsList"
              :loading="isLoading"
              :search-input.sync="searchQuery"
              @update:search-input="debouncedFetchItems(searchQuery)"
              :no-filter="true"
              chips
              clearable
              hide-details
              hide-selected
              return-object
              dense
              outlined
              label="Search with client name or client Email"
              @blur="$v.selectedClient.$touch()"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>
                    Search to select a
                    <strong>Client</strong>
                  </v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:selection="{ attr, on, item, selected }">
                <v-chip
                  v-bind="attr"
                  :input-value="selected"
                  color="grey lighten-2"
                  v-on="on"
                >
                  <span
                    v-if="item.project_internal_name"
                    v-text="
                      `${item.client_name} - ${item.project_internal_name}`
                    "
                  ></span>
                  <span v-else v-text="`${item.client_name}`"></span>
                </v-chip>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title
                    v-text="`${item.client_name} - ${item.client_email}`"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.project_internal_name"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <VulidateError
              class="mt-4"
              :validationField="$v.selectedClient"
              :params="['required']"
            />
          </div>
          <div class="col-12 mt-7">
            <div class="row">
              <div class="col-6">
                <label class="form__label"
                  >Contract Type <span class="text-danger">*</span></label
                >
                <v-select
                  dense
                  outlined
                  v-model="contractType"
                  :items="contractTypeList"
                  class="v-select-input__contract-type"
                >
                  <template v-slot:selection="{ attr, on, item, selected }">
                    <v-chip
                      v-bind="attr"
                      :input-value="selected"
                      color="grey lighten-2"
                      v-on="on"
                      class="mb-1"
                    >
                      <span v-text="item.name"></span>
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="`${item.name}`"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
                <VulidateError
                  :validationField="$v.contractType"
                  :params="['required']"
                />
              </div>
              <div class="col-6">
                <label class="form__label"
                  >Job Type <span class="text-danger">*</span></label
                >
                <v-select
                  dense
                  outlined
                  v-model="jobType"
                  :items="jobTypeList"
                  class="v-select-input__contract-type"
                >
                  <template v-slot:selection="{ attr, on, item, selected }">
                    <v-chip
                      v-bind="attr"
                      :input-value="selected"
                      color="grey lighten-2"
                      v-on="on"
                      class="mb-1"
                    >
                      <span v-text="item.text"></span>
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="`${item.text}`"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
                <VulidateError
                  :validationField="$v.jobType"
                  :params="['required']"
                />
              </div>
            </div>
          </div>
          <div class="col-12 mt-7">
            <div class="row">
              <!-- <div class="col-6">
                <label class="form__label">Account Manager</label>
                <v-select
                  dense
                  outlined
                  item-text="name"
                  item-value="id"
                  v-model="accountManager"
                  :items="accountManagers"
                  class="v-select-input__renewal-type"
                >
                  <template v-slot:selection="{ attr, on, item, selected }">
                    <v-chip
                      v-bind="attr"
                      :input-value="selected"
                      color="grey lighten-2"
                      v-on="on"
                      class="mb-1"
                    >
                      <span v-text="item.name"></span>
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="`${item.name}`"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </div> -->
              <div class="col-6">
                <label class="form__label"
                  >Renewal Type <span class="text-danger">*</span></label
                >
                <v-select
                  dense
                  outlined
                  v-model="renewalType"
                  :disabled="
                    contractType == 3 ||
                    jobType == 'onsite-accommodation' ||
                    jobType == 'onsite-business-visa'
                  "
                  :items="renewalTypeList"
                  class="v-select-input__renewal-type"
                >
                  <template v-slot:selection="{ attr, on, item, selected }">
                    <v-chip
                      v-bind="attr"
                      :input-value="selected"
                      color="grey lighten-2"
                      v-on="on"
                      class="mb-1"
                    >
                      <span v-text="item.name"></span>
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="`${item.name}`"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
                <VulidateError
                  :validationField="$v.renewalType"
                  :params="['required']"
                />
              </div>
              <div class="col-6">
                <label class="form__label">
                  Monthly Salary (USD) <span class="text-danger">*</span>
                </label>
                <v-text-field
                  type="number"
                  outlined
                  prepend-icon="mdi-coin"
                  v-model.number="monthlySalary"
                  @blur="$v.monthlySalary.$touch()"
                  @input="$v.monthlySalary.$touch()"
                ></v-text-field>
                <VulidateError
                  :validationField="$v.monthlySalary"
                  :params="[
                    'required',
                    'decimal',
                    {
                      minValue: {
                        number: $v.monthlySalary.$params.minValue.min,
                      },
                    },
                    {
                      maxValue: {
                        number: $v.monthlySalary.$params.maxValue.max,
                      },
                    },
                  ]"
                />
              </div>
            </div>
          </div>
          <div class="col-12 mt-7">
            <div class="row">
              <div class="col-6">
                <label class="form__label"
                  >Margin (USD) <span class="text-danger">*</span></label
                >
                <v-text-field
                  type="number"
                  outlined
                  prepend-icon="mdi-coin"
                  v-model.number="margin"
                  @blur="$v.margin.$touch()"
                  @input="$v.margin.$touch()"
                ></v-text-field>
                <VulidateError
                  :validationField="$v.margin"
                  :params="[
                    'required',
                    'decimal',
                    {
                      minValue: {
                        number: 0,
                      },
                    },
                  ]"
                />
              </div>
              <div class="col-6">
                <label class="form__label">
                  WHT (USD) <span class="text-danger"></span>
                </label>
                <v-text-field
                  type="number"
                  outlined
                  prepend-icon="mdi-coin"
                  :value="Number(wht).toFixed(2)"
                  disabled
                ></v-text-field>
              </div>
            </div>
          </div>
          <div class="col-12 mt-7">
            <div class="row">
              <div class="col-6">
                <label class="form__label"
                  >Start Date <span class="text-danger">*</span></label
                >

                <v-dialog
                  ref="startDataDialog"
                  v-model="startDataMenu"
                  :return-value.sync="startDate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startDate"
                      outlined
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="startDate"
                    :max="
                      endData && endData.length > 0
                        ? new Date(
                            new Date(endData).setDate(
                              new Date(endData).getDate() - 1
                            )
                          )
                            .toISOString()
                            .substr(0, 10)
                        : endData
                    "
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="startDataMenu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.startDataDialog.save(startDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                <VulidateError
                  :validationField="$v.startDate"
                  :params="['required']"
                />
              </div>
              <div class="col-6">
                <label class="form__label"
                  >End Date <span class="text-danger">*</span></label
                >
                <v-dialog
                  ref="endDataDialog"
                  v-model="endDataMenu"
                  :return-value.sync="endData"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endData"
                      outlined
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endData"
                    :min="
                      startDate
                        ? new Date(
                            new Date(startDate).setDate(
                              new Date(startDate).getDate() + 1
                            )
                          )
                            .toISOString()
                            .substr(0, 10)
                        : null
                    "
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="endDataMenu = false">
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.endDataDialog.save(endData)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                <VulidateError
                  :validationField="$v.endData"
                  :params="['required']"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="text-right">
          <button
            class="btn btn-secondary font-weight-bolder font-size-h6 px-8"
            type="button"
            @click="resetModal"
          >
            Cancel
          </button>
          <button
            class="btn btn-info font-weight-bolder font-size-h6 px-8 ml-5"
            :class="{
              'spinner spinner-light spinner-right': isLoadingSubmitted,
            }"
            :disabled="isLoadingSubmitted"
          >
            Save
          </button>
        </div>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalHeading from "@/components/UI/ModalHeading.vue";
import VulidateError from "@/components/shared/vulidateError.vue";
import { SALARY_MIN_VALUE, SALARY_MAX_VALUE } from "@/constants/salary";
import debounce from "lodash.debounce";

import {
  required,
  decimal,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  name: "AddClientToTalent",
  components: { ModalHeading, VulidateError },
  mixins: [validationMixin],

  props: {
    contracts: {
      type: Array,
      required: true,
    },
    activeClient: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      formMode: "add",
      isLoadingSubmitted: false,
      selectedClient: null,
      searchQuery: null,
      monthlySalary: null,
      // accountManager: null,
      margin: null,
      startDate: null,
      endData: null,
      wht: 0,
      contractType: null,
      renewalType: "auto",
      jobType: null,
      startDataMenu: false,
      endDataMenu: false,
      contractTypeList: [
        {
          name: "Full time",
          value: 2,
        },
        {
          name: "Part time",
          value: 1,
        },
        {
          name: "Monthly Hours",
          value: 3,
        },
      ],
      renewalTypeList: [
        {
          name: "Auto",
          value: "auto",
        },
        {
          name: "Manual",
          value: "manual",
        },
      ],
      jobTypeList: [
        {
          text: "Remote",
          value: "remote",
        },
        {
          text: "Onsite - Iqama",
          value: "onsite-accommodation",
        },
        {
          text: "Onsite - Business Visa",
          value: "onsite-business-visa",
        },
      ],
    };
  },
  watch: {
    selectedClient(after, before) {
      if (before && !after) {
        // removed
        this.debouncedFetchItems("");
      }
    },
    contractType(newVal) {
      if (
        newVal == 3 ||
        this.jobType == "onsite-accommodation" ||
        this.jobType == "onsite-business-visa"
      ) {
        this.renewalType = "manual";
      } else {
        this.renewalType =
          this.formMode == "add"
            ? this.selectedClient.project_renewal_type
              ? this.selectedClient.project_renewal_type
              : "auto"
            : "add";
      }
    },
    monthlySalary() {
      this.wht = this.updateWht();
    },
    jobType(newVal) {
      this.wht = this.updateWht();
      if (
        newVal == "onsite-accommodation" ||
        newVal == "onsite-business-visa" ||
        this.contractType == 3
      ) {
        this.renewalType = "manual";
      } else if (this.contractType != 3) {
        this.renewalType =
          this.formMode == "add"
            ? this.selectedClient.project_renewal_type
            : this.activeClient.renewal_type;
      }
    },
    activeClient(newVal) {
      if (newVal) {
        this.formMode = "edit";
        this.setClientsList([
          {
            client_email: newVal.client_email,
            project_id: newVal.client_project_id,
            client_name: newVal.client_name,
            project_name: newVal.project_name,
          },
        ]);
        this.selectedClient = {
          client_email: newVal.client_email,
          project_id: newVal.client_project_id,
          client_name: newVal.client_name,
          project_name: newVal.project_name,
        };
        this.startDate = newVal.start_date;
        this.endData = newVal.end_date;
        this.contractType = newVal.contract_type;
        this.renewalType = newVal.renewal_type;
        this.monthlySalary = newVal.salary.basic_salary;
        // this.accountManager = newVal.account_manager_id;
        this.margin = newVal.salary.margin;
        this.wht = newVal.salary.wht_value;
        this.jobType = newVal.job_type;
      } else {
        this.formMode = "add";
        this.selectedClient = null;
        this.searchQuery = null;
        this.startDate = null;
        this.endData = null;
        this.contractType = null;
        this.renewalType = "auto";
        this.monthlySalary = null;
        // this.accountManager = null;
        this.jobType = null;
      }
    },
  },
  validations: {
    selectedClient: { required },
    startDate: { required },
    endData: { required },
    contractType: { required },
    renewalType: { required },
    jobType: { required },
    monthlySalary: {
      required,
      decimal,
      minValue: minValue(SALARY_MIN_VALUE),
      maxValue: maxValue(SALARY_MAX_VALUE),
    },
  },
  computed: {
    ...mapGetters({
      clientsList: "talentProfile/getClientsList",
      // accountManagers: "contracts/getAccountManagers",
    }),
    disablePastDates(val) {
      return val >= new Date().toISOString().substr(0, 10);
    },
    clinetAbleToSelectFrom() {
      let clinetAbleToSelectFrom = this.clientsList.filter((client) => {
        return this.contracts.some((contract) => {
          return (
            contract.client_id !== client.id && contract.email !== client.email
          );
        });
      });

      return clinetAbleToSelectFrom.length
        ? clinetAbleToSelectFrom
        : this.clientsList;
    },
  },
  async mounted() {
    this.debouncedFetchItems = debounce(this.handleGettingClientsList, 300);
    this.debouncedFetchItems("");
    // await this.getAccountManagers("operating");
  },
  methods: {
    ...mapActions({
      getClientsList: "talentProfile/getClientsList",
      addClientToTalent: "talentProfile/addClientToTalent",
      updateClientToTalent: "talentProfile/updateClientToTalent",
      setClientsList: "talentProfile/setClientsList",
      // getAccountManagers: "contracts/getAccountManagers",
    }),
    updateWht() {
      if (this.jobType == "onsite-accommodation") return 0;
      else return this.monthlySalary * 0.05;
    },
    resetModal() {
      this.dialog = false;
      this.$v.$reset();
      this.selectedClient = null;
      this.monthlySalary = null;
      this.searchQuery = null;
      this.startDate = null;
      this.endData = null;
      this.contractType = null;
      this.renewalType = "auto";
      this.monthlySalary = null;
      this.margin = null;
      this.jobType = null;
    },
    submitForm() {
      this.$v.$touch();
      if (!this.$v.$anyError) {
        this.handleAddingClientToTalent();
      }
    },
    handleAddingClientToTalent() {
      this.isLoadingSubmitted = true;
      if (this.formMode == "add") {
        const data = {
          client_project_id: this.selectedClient.project_id,
          talent_id: Number(this.$route.params.id),
          contract_type: this.contractType,
          renewal_type: this.renewalType,
          monthly_salary: this.monthlySalary,
          margin: this.margin,
          wht: this.monthlySalary,
          // account_manager_id: this.accountManager,
          start_date: this.startDate,
          end_date: this.endData,
          job_type: this.jobType,
        };

        let payload = {
          client: data,
          talentId: this.$route.params.id,
        };
        this.addClientToTalent(payload)
          .then(() => {
            this.isLoadingSubmitted = false;
            this.dialog = false;
            this.resetModal();
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            this.isLoadingSubmitted = false;
          });
      } else {
        const data = {
          client_project_id: this.selectedClient.project_id,
          talent_id: Number(this.$route.params.id),
          contract_type: this.contractType,
          renewal_type: this.renewalType,
          start_date: this.startDate,
          end_date: this.endData,
          job_type: this.jobType,
          monthly_salary: this.monthlySalary,
          // account_manager_id: this.accountManager,
          margin: this.margin,
          wht: this.wht,
        };

        let payload = {
          contractId: this.activeClient.id,
          talentId: this.$route.params.id,
          data,
        };

        this.updateClientToTalent(payload)
          .then(() => {
            this.isLoadingSubmitted = false;
            this.dialog = false;
            this.resetModal();
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.isLoadingSubmitted = false;
          });
      }
    },
    handleGettingClientsList(payload) {
      this.isLoading = true;
      this.getClientsList(payload || "")
        .then(() => {
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
  },
};
</script>

<style lang="scss">
.v-select-input__contract-type input {
  cursor: pointer;
}

.v-input--is-disabled {
  cursor: not-allowed;

  .v-text-field__details {
    display: none;
  }

  .v-input__slot {
    cursor: not-allowed;
    margin-bottom: 0;
  }
}
</style>
