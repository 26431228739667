<template>
  <div class="card card-custom gutter-b">
    <div class="card-body">
      <div class="row">
        <div class="col-xl-6 mb-5 mb-lg-0 d-flex flex-column">
          <h5
            class="mb-2 font-weight-bolder font-size-md grey--text"
            v-if="
              profile.basicInfo.availability_updated_by ||
              profile.basicInfo.availability_updated_at
            "
          >
            Availability last Updated
            <template v-if="profile.basicInfo.availability_updated_by">
              by {{ profile.basicInfo.availability_updated_by.name }}
            </template>
            <template v-if="profile.basicInfo.availability_updated_at">
              on
              {{
                new Date(
                  profile.basicInfo.availability_updated_at
                ).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })
              }}
            </template>
          </h5>
          <h6 class="mb-1 font-weight-bolder font-size-sm">
            Full Time Availability
          </h6>
          <div class="d-flex">
            <select
              ref="fullTimeAvailability"
              :disabled="!editFullTimeAvailability"
              :read-only="!editFullTimeAvailability"
              v-model="fullTimeAvailability"
              class="custom-select"
            >
              <option :value="null" disabled>Please select an option</option>
              <option
                :value="option.id"
                v-for="option in AVAILABILITY_OPTIONS"
                :key="option.id"
              >
                {{ option.name }}
              </option>
            </select>
            <div class="d-flex" v-if="editFullTimeAvailability">
              <b-button
                size="sm"
                variant="info"
                class="px-2 ml-3"
                @click="
                  handleEditClick(
                    'fullTimeAvailability',
                    'editFullTimeAvailability'
                  )
                "
                >Ok</b-button
              >
              <b-button
                size="sm"
                variant="outline-secondary"
                class="px-2 ml-3"
                @click="
                  cancelEdit('fullTimeAvailability', 'editFullTimeAvailability')
                "
                >Cancel</b-button
              >
            </div>
            <div
              v-else
              v-b-tooltip="
                !profile.basicInfo.allow_sharable_link
                  ? 'Please complete profile first, then update availability'
                  : ''
              "
            >
              <b-button
                size="sm"
                variant="outline-info"
                :disabled="!profile.basicInfo.allow_sharable_link"
                class="px-2 ml-3"
                @click="
                  handleShowingEditInput(
                    'fullTimeAvailability',
                    'editFullTimeAvailability'
                  )
                "
                ><i class="fas fa-pen px-0"></i
              ></b-button>
            </div>
          </div>
          <VulidateError
            :validationField="$v.fullTimeAvailability"
            :params="['required']"
          />
          <div class="mt-4">
            <h6 class="mb-1 font-weight-bolder font-size-sm">
              Part Time Availability
            </h6>
            <div class="d-flex">
              <select
                ref="partTimeAvailability"
                :disabled="!editPartTimeAvailability"
                :read-only="!editPartTimeAvailability"
                v-model="partTimeAvailability"
                class="custom-select"
              >
                <option :value="null" disabled>Please select an option</option>
                <option
                  :value="option.id"
                  v-for="option in AVAILABILITY_OPTIONS"
                  :key="option.id"
                >
                  {{ option.name }}
                </option>
              </select>
              <div class="d-flex" v-if="editPartTimeAvailability">
                <b-button
                  size="sm"
                  variant="info"
                  class="px-2 ml-3"
                  @click="
                    handleEditClick(
                      'partTimeAvailability',
                      'editPartTimeAvailability'
                    )
                  "
                  >Ok</b-button
                >
                <b-button
                  size="sm"
                  variant="outline-secondary"
                  class="px-2 ml-3"
                  @click="
                    cancelEdit(
                      'partTimeAvailability',
                      'editPartTimeAvailability'
                    )
                  "
                  >Cancel</b-button
                >
              </div>
              <div
                v-else
                v-b-tooltip="
                  !profile.basicInfo.allow_sharable_link
                    ? 'Please complete profile first, then update availability'
                    : ''
                "
              >
                <b-button
                  size="sm"
                  variant="outline-info"
                  class="px-2 ml-3"
                  :disabled="!profile.basicInfo.allow_sharable_link"
                  @click="
                    handleShowingEditInput(
                      'partTimeAvailability',
                      'editPartTimeAvailability'
                    )
                  "
                  ><i class="fas fa-pen px-0"></i
                ></b-button>
              </div>
            </div>
            <VulidateError
              :validationField="$v.partTimeAvailability"
              :params="['required']"
            />
          </div>

          <div class="mt-4">
            <h5
              class="mb-2 font-weight-bolder font-size-md grey--text"
              v-if="
                profile.basicInfo.salary_updated_by ||
                profile.basicInfo.salary_updated_at
              "
            >
              Salary last Updated
              <template v-if="profile.basicInfo.salary_updated_by">
                by {{ profile.basicInfo.salary_updated_by.name }}
              </template>
              <template v-if="profile.basicInfo.salary_updated_at">
                on
                {{
                  new Date(
                    profile.basicInfo.salary_updated_at
                  ).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                }}
              </template>
            </h5>
            <h6 class="mb-1 font-weight-bolder font-size-sm">
              Expected Full Time Salary
            </h6>
            <div class="d-flex">
              <input
                class="form-control form__input"
                :disabled="!editFullTimeSalary"
                :read-only="!editFullTimeSalary"
                ref="fullTimeSalary"
                v-model.number="fullTimeSalary"
                :class="{
                  'form__input--error':
                    $v.fullTimeSalary.$dirty && $v.fullTimeSalary.$error,
                }"
                @keyup.enter="
                  handleEditClick('fullTimeSalary', 'editFullTimeSalary')
                "
              />
              <div class="d-flex" v-if="editFullTimeSalary">
                <b-button
                  size="sm"
                  variant="info"
                  class="px-2 ml-3"
                  @click="
                    handleEditClick('fullTimeSalary', 'editFullTimeSalary')
                  "
                  >Ok</b-button
                >
                <b-button
                  size="sm"
                  variant="outline-secondary"
                  class="px-2 ml-3"
                  @click="cancelEdit('fullTimeSalary', 'editFullTimeSalary')"
                  >Cancel</b-button
                >
              </div>
              <b-button
                v-else
                size="sm"
                variant="outline-info"
                class="px-2 ml-3"
                @click="
                  handleShowingEditInput('fullTimeSalary', 'editFullTimeSalary')
                "
                ><i class="fas fa-pen px-0"></i
              ></b-button>
            </div>

            <VulidateError
              :validationField="$v.fullTimeSalary"
              :params="[
                'integer',
                {
                  minValue: { number: $v.fullTimeSalary.$params.minValue.min },
                },
                {
                  maxValue: { number: $v.fullTimeSalary.$params.maxValue.max },
                },
              ]"
            />
          </div>
          <div class="mt-4">
            <h6 class="mb-1 font-weight-bolder font-size-sm">
              Expected Part Time Salary
            </h6>
            <div class="d-flex">
              <input
                class="form-control form__input"
                :disabled="!editPartTimeSalary"
                :read-only="!editPartTimeSalary"
                v-model.number="partTimeSalary"
                ref="partTimeSalary"
                :class="{
                  'form__input--error':
                    $v.partTimeSalary.$dirty && $v.partTimeSalary.$error,
                }"
                @keyup.enter="
                  handleEditClick('partTimeSalary', 'editPartTimeSalary')
                "
              />
              <div class="d-flex" v-if="editPartTimeSalary">
                <b-button
                  size="sm"
                  variant="info"
                  class="px-2 ml-3"
                  @click="
                    handleEditClick('partTimeSalary', 'editPartTimeSalary')
                  "
                  >Ok</b-button
                >
                <b-button
                  size="sm"
                  variant="outline-secondary"
                  class="px-2 ml-3"
                  @click="cancelEdit('partTimeSalary', 'editPartTimeSalary')"
                  >Cancel</b-button
                >
              </div>
              <b-button
                v-else
                size="sm"
                variant="outline-info"
                class="px-2 ml-3"
                @click="
                  handleShowingEditInput('partTimeSalary', 'editPartTimeSalary')
                "
                ><i class="fas fa-pen px-0"></i
              ></b-button>
            </div>

            <VulidateError
              :validationField="$v.partTimeSalary"
              :params="[
                'integer',
                {
                  minValue: { number: $v.partTimeSalary.$params.minValue.min },
                },
                {
                  maxValue: { number: $v.partTimeSalary.$params.maxValue.max },
                },
              ]"
            />
          </div>
        </div>

        <div class="col-xl-6 d-flex flex-column">
          <div class="shadow p-4 flex-grow-1">
            <!-- <v-alert type="info" outlined dense>
              <span class="font-size-lg"
                >Margin = 500 + 5%(Full-time Expected Salary) =
                <span v-if="profile.basicInfo.salary">
                  ${{ 500 + profile.basicInfo.salary * (5 / 100) }}
                </span>
                <strong v-else>-</strong>
              </span>
            </v-alert> -->
            <p class="mb-3 font-size-lg">
              Margin =
              <strong class="text-info">
                ${{ profile.basicInfo.full_price.margin.margin }}
              </strong>
              <strong v-if="false">0</strong>
            </p>
            <p class="mb-3 font-size-lg">
              <i
                class="flaticon2-information icon-md text-info mr-1"
                v-b-tooltip="`WHT = 5% from (Full-time Expected Salary)`"
              ></i>
              WHT =
              <strong class="text-info">
                ${{ profile.basicInfo.full_price.margin.wht_value }}
              </strong>
            </p>
            <p class="mb-3 font-size-lg">
              Full Time Expected Salary:
              <strong class="text-info" v-if="profile.basicInfo.salary">
                ${{ profile.basicInfo.salary }}</strong
              >
              <strong v-else> -</strong>
            </p>
            <p class="mb-0 font-size-lg">
              Total Full Time Salary with Margin :
              <strong
                class="text-info"
                v-if="profile.basicInfo.full_price.total"
              >
                ${{ profile.basicInfo.full_price.total }}</strong
              >
              <strong v-else>-</strong>
            </p>
          </div>

          <div class="shadow p-4 flex-grow-1 mt-4">
            <!-- <v-alert type="info" outlined dense>
              <span class="font-size-lg"
                >Margin = 300 + 5%(Part-time Expected Salary) =
              </span>
              <span v-if="profile.basicInfo.part_time_salary">
                ${{ 300 + profile.basicInfo.part_time_salary * (5 / 100) }}
              </span>
              <strong v-else>-</strong>
            </v-alert> -->
            <p class="mb-3 font-size-lg">
              Margin =
              <strong class="text-info">
                ${{ profile.basicInfo.part_price.margin.margin }}
              </strong>
              <strong class="text-info" v-if="false">0</strong>
            </p>
            <p class="mb-3 font-size-lg">
              <i
                class="flaticon2-information icon-md text-info mr-1"
                v-b-tooltip="`WHT = 5% from (Part-time Expected Salary)`"
              ></i>
              WHT =
              <strong class="text-info">
                ${{ profile.basicInfo.part_price.margin.wht_value }}
              </strong>
            </p>
            <p class="mb-3 font-size-lg">
              Part Time Expected Salary:
              <strong
                class="text-info"
                v-if="profile.basicInfo.part_time_salary"
              >
                ${{ profile.basicInfo.part_time_salary }}
              </strong>
              <strong v-else>-</strong>
            </p>
            <p class="mb-0 font-size-lg">
              Total Part Time Salary with Margin :
              <strong
                class="text-info"
                v-if="profile.basicInfo.part_price.total"
              >
                ${{ profile.basicInfo.part_price.total }}
              </strong>
              <strong v-else> -</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  required,
  integer,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import AVAILABILITY_OPTIONS from "@/constants/availability-options";
import { SALARY_MIN_VALUE, SALARY_MAX_VALUE } from "@/constants/salary";
import VulidateError from "@/components/shared/vulidateError.vue";
export default {
  name: "SalaryAndAvailability",
  mixins: [validationMixin],
  components: { VulidateError },
  props: {
    profile: {
      required: true,
      type: Object,
    },
    profileId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      AVAILABILITY_OPTIONS,
      editFullTimeSalary: false,
      editPartTimeSalary: false,
      editFullTimeAvailability: false,
      editPartTimeAvailability: false,
      fullTimeAvailability: null,
      partTimeAvailability: null,
      fullTimeSalary: null,
      partTimeSalary: null,
    };
  },
  watch: {
    profile: {
      immediate: true,
      handler(profile) {
        this.initSalaryData(profile);
      },
    },
  },
  validations: {
    partTimeSalary: {
      integer,
      minValue: minValue(SALARY_MIN_VALUE),
      maxValue: maxValue(SALARY_MAX_VALUE),
    },
    fullTimeSalary: {
      integer,
      minValue: minValue(SALARY_MIN_VALUE),
      maxValue: maxValue(SALARY_MAX_VALUE),
    },
    partTimeAvailability: {
      required,
    },
    fullTimeAvailability: {
      required,
    },
  },
  methods: {
    ...mapActions({
      editTalentSalary: "talentProfile/editTalentSalary",
      editTalentAvailability: "talentProfile/editTalentAvailability",
    }),
    initSalaryData(profile = this.profile) {
      this.partTimeSalary = profile?.basicInfo?.part_time_salary || "";
      this.fullTimeSalary = profile?.basicInfo?.salary || "";
      this.partTimeAvailability = !isNaN(profile.basicInfo?.part_time_status)
        ? profile.basicInfo?.part_time_status
        : null;
      this.fullTimeAvailability = !isNaN(profile.basicInfo?.full_time_status)
        ? profile.basicInfo?.full_time_status
        : null;
    },
    handleShowingEditInput(target, toggleKey) {
      this[toggleKey] = true;

      this.$nextTick(() => {
        this.$refs[target].focus();
      });
    },
    handleEditClick(target, toggleKey) {
      let inputValue = this.$refs[target].value;
      this.$v[target].$touch();
      if (this.$v[target].$invalid) {
        return;
      }
      let data;
      switch (target) {
        case "fullTimeAvailability":
          data = {
            id: this.profileId,
            full_time_status: +inputValue,
          };
          this.editTalentAvailability(data).then(() => {
            this[toggleKey] = false;
            this.initSalaryData();
          });
          break;
        case "partTimeAvailability":
          data = {
            id: this.profileId,
            part_time_status: +inputValue,
          };
          this.editTalentAvailability(data).then(() => {
            this[toggleKey] = false;
            this.initSalaryData();
          });
          break;
        case "fullTimeSalary":
          data = {
            id: this.profileId,
            salary: inputValue,
          };
          this.editTalentSalary(data).then(() => {
            this[toggleKey] = false;
            this.initSalaryData();
          });
          break;
        case "partTimeSalary":
          data = {
            id: this.profileId,
            part_time_salary: inputValue,
          };
          this.editTalentSalary(data).then(() => {
            this[toggleKey] = false;
            this.initSalaryData();
          });
          break;
      }
    },
    cancelEdit(target, toggleKey) {
      this.$v[target].$reset();
      this[toggleKey] = false;
      this.initSalaryData();
    },
  },
};
</script>
